import { ThemeProvider } from "@material-ui/core/styles";
import React, { ReactNode } from "react";

import generateTheme from "./generateTheme";

const wrapRootElement = ({ element }: { element: ReactNode }) => (
  <ThemeProvider theme={generateTheme(false)}>{element}</ThemeProvider>
);

export default wrapRootElement;
