import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const defaultTheme = createMuiTheme();

// https://coolors.co/009cfc-41af43-fb3640-fffffa-333333

export default function generateTheme(darkMode: boolean) {
  // const bgOverlay = darkMode
  //   ? "rgba(0, 0, 0, 0.8)"
  //   : "rgba(255, 255, 255, 0.8)";

  const theme = createMuiTheme({
    palette: {
      type: darkMode ? "dark" : "light",
      primary: {
        // light: string,
        main: "#B57BFF",
        // dark: string,
        // contrastText: string,
      },
      secondary: {
        main: "#5393fe",
      },
      success: {
        main: "#6CBB47",
      },
      error: {
        main: "#F46B3F",
      },
    },
    props: {
      MuiLink: {
        color: "secondary",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          // body: {
          //   backgroundImage: `linear-gradient(${bgOverlay} 100%, ${bgOverlay} 100%), url('/nasa-WKT3TE5AQu0-unsplash.jpg')`,
          //   backgroundAttachment: "fixed",
          //   backgroundSize: "cover",
          //   backgroundPosition: "center",
          // },
          "::selection": {
            background: "rgba(181,123,255,0.4)",
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: defaultTheme.typography.body1.fontSize,
        },
      },
      // Fix for link hover colour
      MuiLink: {
        root: {
          "&:hover": {
            color: "#5393fe", // Same as palette.secondary.main
          },
        },
      },
      MuiListItem: {
        root: {
          "&$selected": {
            borderLeft: `${defaultTheme.spacing(0.5)}px solid #B57BFF`,
            paddingLeft: defaultTheme.spacing(1.5),
          },
        },
      },
    },
  });

  return responsiveFontSizes(theme);
}
